exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{BlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-blog-post-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-modular-page-slug-tsx": () => import("./../../../src/pages/{ModularPage.slug}.tsx" /* webpackChunkName: "component---src-pages-modular-page-slug-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-retreats-retreat-slug-tsx": () => import("./../../../src/pages/retreats/{Retreat.slug}.tsx" /* webpackChunkName: "component---src-pages-retreats-retreat-slug-tsx" */),
  "component---src-pages-retreats-tsx": () => import("./../../../src/pages/retreats.tsx" /* webpackChunkName: "component---src-pages-retreats-tsx" */),
  "component---src-pages-stundenplan-tsx": () => import("./../../../src/pages/stundenplan.tsx" /* webpackChunkName: "component---src-pages-stundenplan-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

